










import { Component } from 'vue-property-decorator'
import Slide from '@/mixins/Slide.ts'
import { mixins } from 'vue-class-component'
import { getResourceUrl } from '@/utils/get-resource-url'
import LottieAnimation from '@/components/LottieAnimation.vue'
import ScrollerElement from '@/components/ScrollerElement.vue'
import BasicIntersectionObservable from '@/mixins/BasicIntersectionObservable'
import CheatSheet from '@/components/CheatSheet.vue'

@Component({
    components: { LottieAnimation, ScrollerElement, CheatSheet }
})
export default class SlideCustomAnimation extends mixins(Slide, BasicIntersectionObservable) {
    get walkerItem() {
        return this.walker.item as SlideWalkerItemCustomAnimation
    }

    get animationFileUrl() {
        return getResourceUrl(this.walkerItem.animationFile?.[0]?.url) || null
    }

    /**
     * @override
     */
    onObservableEnter() {
        if (this.$refs.lottie && this.$refs.lottie instanceof LottieAnimation) {
            this.$refs.lottie.play()
        }
    }

    /**
     * @override
     */
    onObservableLeave() {
        if (this.$refs.lottie && this.$refs.lottie instanceof LottieAnimation) {
            this.$refs.lottie.stop()
        }
    }
}
